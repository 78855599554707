import '@fontsource/open-sans' // Defaults to weight 400 with normal variant.

import i18n from 'i18next'
import {
    initReactI18next,
    I18nextProvider,
    useTranslation,
} from 'react-i18next'
import React, { useEffect } from 'react'
import enUSTranslation from './src/locales/en_US/translation.json'
import esARTranslation from './src/locales/es_AR/translation.json'
import es419Translation from './src/locales/es_419/translation.json'
import esUSTranslation from './src/locales/es_US/translation.json'
import esESTranslation from './src/locales/es_ES/translation.json'
import esCLTranslation from './src/locales/es_CL/translation.json'
import esCOTranslation from './src/locales/es_CO/translation.json'
import esMXTranslation from './src/locales/es_MX/translation.json'
import esVETranslation from './src/locales/es_VE/translation.json'
import esUYTranslation from './src/locales/es_UY/translation.json'
import esPETranslation from './src/locales/es_PE/translation.json'
import ptBRTranslation from './src/locales/pt_BR/translation.json'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from './src/services/queryClient'
import { TourProvider } from '@reactour/tour'
import tourStyles from './src/components/Tour'
import { createDevice } from './src/services/device'
import '@ecolell/pwa-install'

const resources = {
    'en-US': {
        translation: enUSTranslation,
    },
    'es-AR': {
        translation: esARTranslation,
    },
    'es-ES': {
        translation: esESTranslation,
    },
    'es-419': {
        translation: es419Translation,
    },
    'es-US': {
        translation: esUSTranslation,
    },
    'es-CL': {
        translation: esCLTranslation,
    },
    'es-CO': {
        translation: esCOTranslation,
    },
    'es-MX': {
        translation: esMXTranslation,
    },
    'es-VE': {
        translation: esVETranslation,
    },
    'es-UY': {
        translation: esUYTranslation,
    },
    'es-PE': {
        translation: esPETranslation,
    },
    'pt-BR': {
        translation: ptBRTranslation,
    },
    // Add more languages as needed
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'es-AR', // Default language fallback
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // React already escapes strings
    },
})

const generateUUIDFromDatetime = async () => {
    // Get the current time as a string
    const currentTime = new Date().toISOString()
    // Generate a hash using the SubtleCrypto API
    const hashBuffer = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(currentTime)
    )
    // Convert the hash to a hexadecimal string
    const hexString = Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('')
    // Use the first 32 characters for the UUID (similar to UUID v4 format)
    return `${hexString.substring(0, 8)}-${hexString.substring(
        8,
        12
    )}-${hexString.substring(12, 16)}-${hexString.substring(
        16,
        20
    )}-${hexString.substring(20, 32)}`
}

export const wrapPageElement = ({ element }) => {
    const { i18n } = useTranslation()

    useEffect(() => {
        const n = window?.navigator
        const lng = n.language || n.userLanguage
        if (Object.keys(resources).includes(lng)) {
            i18n.changeLanguage(lng)
        }
        const deviceId = localStorage.getItem('deviceId')
        if (!deviceId) {
            generateUUIDFromDatetime().then((value) => {
                createDevice({ id: value }).then((data) => {
                    if (data?.id == value) {
                        localStorage.setItem('deviceId', value)
                    }
                })
            })
        }
    }, [])

    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <TourProvider
                    steps={[]}
                    scrollSmooth={true}
                    showDots={false}
                    showCloseButton={true}
                    showNavigation={true}
                    showPrevNextButtons={true}
                    showBadge={false}
                    styles={tourStyles(theme)}
                >
                    {element}
                </TourProvider>
            </ThemeProvider>
        </React.StrictMode>
    )
}

export const wrapRootElement = ({ element }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
        </QueryClientProvider>
    )
}

export const onServiceWorkerUpdateReady = () => {
    // const { t } = useTranslation()

    // const answer = window.confirm(
    //     t(
    //         'This application has been updated. ' +
    //             'Reload to display the latest version?'
    //     )
    // )

    // if (answer === true) {
    window.location.reload()
    // }
}
