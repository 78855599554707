import { createTheme } from '@mui/material/styles'
const MAIN = '#5C9EAD'
const LIGHT = '#FFFFFF'
const DARK = '#326273'
const CONTRAST_TEXT = '#EEEEEE'

const theme = createTheme({
    palette: {
        primary: {
            main: MAIN,
            light: LIGHT,
            dark: DARK,
            contrastText: CONTRAST_TEXT,
            warning: '#cccc99',
        },
        secondary: {
            main: DARK,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: DARK,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: MAIN,
                    border: MAIN,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: MAIN,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: DARK,
                },
            },
        },
    },
    // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
    },
})

export default theme
