import { Theme } from '@emotion/react'

const tourStyles = (theme: Theme) => {
    return {
        // Customize styles here
        mask: (base: object) => ({
            ...base,
            backgroundColor: 'rgba(255, 0, 0, 255)', // Lighter mask color
        }),
        badge: (base: object) => ({
            ...base,
            left: '45%',
            backgroundColor: theme.palette.primary.main, // Badge color
            color: '#fff', // Badge text color
        }),
        close: (base: object) => ({
            ...base,
            color: theme.palette.primary.main, // Close button color
        }),
        content: (base: object) => ({
            ...base,
            // backgroundColor: '#f00', // Content background color
            color: theme.palette.primary.main, // Content text color
            // boxShadow: '0 0 10px rgba(0, 0, 0, 0.9)', // Light shadow for content box
        }),
        arrow: (base: object) => ({
            ...base,
            color: theme.palette.primary.main, // Arrow color
        }),
    }
}

export default tourStyles
