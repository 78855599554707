import axios from 'axios'
import './datetime'
import { apiBaseUrl } from './config'
import { handleError } from './network'

const deviceURL = `${apiBaseUrl}/api/v1/devices/`

interface Device {
    id?: string
}

// type ListDevice = (businessId: string) => Promise<Device[]>

// const listDevices: ListDevice = async (businessId) => {
//     const config = getAuthorizationHeader()
//     try {
//         const response = await axios.get(
//             `${deviceURL}?business_id=${businessId}`,
//             config
//         )
//         return response.data.devices
//     } catch (error) {
//         handleError(error)
//     }
// }

// const useListDevicesFetch = (businessId: string | null | undefined) => {
//     const {
//         data: devices,
//         isInitialLoading: isLoadingDevices,
//         error: devicesError,
//     } = useQuery(
//         {
//             queryKey: ['listDevices', businessId],
//             queryFn: () => (businessId ? listDevices(businessId) : []),
//             staleTime: 1000 * 10,
//         },
//         queryClient
//     )
//     return { devices: devices || [], isLoadingDevices, devicesError }
// }

type CreateDevice = (objIn: Device) => Promise<Device>

const createDevice: CreateDevice = async (objIn) => {
    try {
        const response = await axios.post(`${deviceURL}`, objIn)
        return response.data
    } catch (error) {
        handleError(error)
    }
}

type PutDeviceNotificationToken = (
    deviceId: string,
    notificationTokenId: string
) => Promise<Device>

const putDeviceNotificationToken: PutDeviceNotificationToken = async (
    deviceId,
    notificationTokenId
) => {
    try {
        const response = await axios.put(
            `${deviceURL}${deviceId}/notification_tokens/${notificationTokenId}`,
            {}
        )
        return response.data
    } catch (error) {
        handleError(error)
    }
}

type DeleteDeviceNotificationToken = (
    deviceId: string,
    notificationTokenId: string
) => Promise<Device>

const deleteDeviceNotificationToken: DeleteDeviceNotificationToken = async (
    deviceId,
    notificationTokenId
) => {
    try {
        const response = await axios.delete(
            `${deviceURL}${deviceId}/notification_tokens/${notificationTokenId}`,
            {}
        )
        return response.data
    } catch (error) {
        handleError(error)
    }
}

type PutDeviceReservationToggle = (
    deviceId: string,
    reservationId: string
) => Promise<Device>

const putDeviceReservationToggle: PutDeviceReservationToggle = async (
    deviceId,
    reservationId
) => {
    try {
        const response = await axios.put(
            `${deviceURL}${deviceId}/reservations/${reservationId}/notifications_toggle`,
            {}
        )
        return response.data
    } catch (error) {
        handleError(error)
    }
}

type GetDeviceReservationToggle = (
    deviceId: string,
    reservationId: string
) => Promise<Device>

const getDeviceReservation: GetDeviceReservationToggle = async (
    deviceId,
    reservationId
) => {
    try {
        const response = await axios.get(
            `${deviceURL}${deviceId}/reservations/${reservationId}/notifications_toggle`,
            {}
        )
        return response.data
    } catch (error) {
        handleError(error)
    }
}

export {
    Device,
    // listDevices,
    // useListDevicesFetch,
    createDevice,
    putDeviceNotificationToken,
    deleteDeviceNotificationToken,
    getDeviceReservation,
    putDeviceReservationToggle,
}
